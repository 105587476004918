import { useState, useEffect } from "react";
import { ArrowRightCircleIcon, ArrowLeftCircleIcon } from '@heroicons/react/20/solid';

export default function Carousel({ children }) {
    const [current, setCurrent] = useState(0);

    const previousSlide = () => {
        setCurrent(current === 0 ? children.length - 1 : current - 1);
    };

    const nextSlide = () => {
        setCurrent(current === children.length - 1 ? 0 : current + 1);
    };

    useEffect(() => {
        const intervalId = setInterval(nextSlide, 10000); // 5 segundos

        return () => {
            clearInterval(intervalId);
        };
    }, [current]);

    return (
        <div className="overflow-hidden relative mt-10">
            <div
                className={`flex transition ease-out duration-500`}
                style={{
                    transform: `translateX(-${current * 100}%)`,
                }}
            >
                {children.map((child, index) => (
                    <div key={index} className="flex-shrink-0 w-full">
                        {child}
                    </div>
                ))}
            </div>

            <div className="absolute top-1/2 left-0 transform -translate-y-1/2 z-10">
                <button onClick={previousSlide}>
                    <ArrowLeftCircleIcon className="h-10 w-10 text-blue-900"/>
                </button>
            </div>
            <div className="absolute top-1/2 right-0 transform -translate-y-1/2 z-10">
                <button onClick={nextSlide}>
                    <ArrowRightCircleIcon className="h-10 w-10 text-blue-900"/>
                </button>
            </div>

            <div className="absolute bottom-0 py-4 flex justify-center gap-2 w-full z-10">
                {children.map((_, i) => (
                    <div
                        onClick={() => setCurrent(i)}
                        key={"circle" + i}
                        className={`rounded-full w-3 h-3 cursor-pointer ${
                            i === current ? "bg-yellow-400" : "bg-gray-500"
                        }`}
                    ></div>
                ))}
            </div>

            <div
                className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-r-[2rem] lg:rounded-l-[2rem]"></div>
        </div>
    );
}
